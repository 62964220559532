/* Scroll bar */
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgb(164, 164, 164);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/* Autocomplete */
.MuiAutocomplete-endAdornment {
    top: 5px !important;
}
